<template>
  <b-dropdown
    class="mark-as-selector"
    :position="position"
    :disabled="disabled">
    <b-button slot="trigger">
      {{ $t('tasks.markAs.title') }}
    </b-button>
    <b-dropdown-item
      v-for="option in markAsOptions"
      :key="`mark_as_${getOptionKeySuffix(option)}`"
      @click="onSelect(option)">
      {{ getOptionLabel(option) }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { MARK_AS_OPTIONS, getOptionKeySuffix, getOptionLabel } from '@js/mark-as-utils'

export default {
  name: 'mark-as-selector',
  props: {
    position: {
      type: String,
      default: 'is-bottom-right'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    markAsOptions() {
      return MARK_AS_OPTIONS
    }
  },
  methods: {
    getOptionKeySuffix(option) {
      return getOptionKeySuffix(option)
    },
    getOptionLabel(option) {
      return getOptionLabel(option)
    },
    onSelect(value) {
      this.$emit('change', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.mark-as-selector {
  &::v-deep {
    .button {
      @extend %body;
    }

    .dropdown-item {
      white-space: normal;
      @extend %small;

      &:not(:last-child) {
        border-bottom: 1px solid $grey-lighter;
      }
    }
  }
}
</style>
